<template>
    <auth-layout :title="$t('password_reset.title')" :button-text="$t('password_reset.button')" :action="submit" :needs-recaptcha="false">
        <validation-provider name="password" rules="required|min:8" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('password_reset.fields.password.label')">
                <input-component
                    v-model="form.password"
                    type="password"
                    :placeholder="$t('password_reset.fields.password.placeholder')"
                    @input="reset"
                    @mousedown.stop
                />
            </input-group>
        </validation-provider>

        <validation-provider name="password_confirmation" rules="required|confirmed:password" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.password_confirmation')">
                <input-component
                    v-model="form.password_confirmation"
                    type="password"
                    :placeholder="$t('password_reset.fields.password_confirmation.placeholder')"
                    @input="reset"
                    @mousedown.stop
                />
            </input-group>
        </validation-provider>
    </auth-layout>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import { resetPassword } from '@/services/skinsPaymentApi';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';

    export default {
        name: 'PasswordReset',
        components: {
            AuthLayout,
            InputComponent,
            InputGroup,
            ValidationProvider,
        },
        methods: {
            submit() {
                return resetPassword(this.form).then(() => {
                    this.$router.push({ name: 'login', params: { successMessage: this.$t('password_reset.success') } });
                });
            },
        },
        data() {
            return {
                form: {
                    email: this.$route.query.email,
                    password: null,
                    password_confirmation: null,
                    token: this.$route.query.token,
                },
            };
        },
    };
</script>
